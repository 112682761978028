import { Box, Flex } from '@square-enix-private/vaquita-ui';
import styled from '@emotion/styled';
import { minHeight, textAlign, margin, fontFamily } from 'styled-system';

export const DescriptionText = styled(Box)`
  ${textAlign};
  ${fontFamily};
`;

export const CharacterBoxBorder = styled(Box)`
  padding: 1px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
  clip-path: polygon(91% 0, 100% 5%, 100% 100%, 0 100%, 0 0);
  background: linear-gradient(
    180deg,
    rgba(45, 45, 45, 1) 0%,
    rgba(45, 45, 45, 0) 100%
  );

  &.selected {
    clip-path: polygon(96% 0, 100% 5%, 100% 100%, 0 100%, 0 0);
    background: linear-gradient(
      180deg,
      rgba(${({ borderColor }) => borderColor}, 1) 0%,
      rgba(${({ borderColor }) => borderColor}, 0) 100%
    );
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const CharacterBox = styled(Box)`
  min-height: 510px;
  background-image: url(${({ src }) => src});
  background-position: top center;
  background-size: cover;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 190px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  clip-path: polygon(91% 0, 100% 5%, 100% 100%, 0 100%, 0 0);

  &.selected {
    clip-path: polygon(96% 0, 100% 5%, 100% 100%, 0 100%, 0 0);
  }
`;

export const FadeOutLink = styled.a`
  outline: none;
  height: 102px;
  width: 164px;
  position: absolute;
  top: 15px;
  left: 15px;
  transition: all 0.3s;
  opacity: 1;

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
`;

export const DescriptionArea = styled(Box)`
  width: 180px;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, 0);
  transition: all 0.3s;

  &.left {
    left: 0;
    transform: translate(0, 0);
  }
  z-index: 2;
`;

export const Icon = styled(Box)`
  width: 140px;
  height: 148px;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  ${margin};
`;

export const CharacterTitle = styled.h5`
  margin: 0;
  padding: 0;
  font-size: 15px;
  text-align: center;
  ${textAlign};
  text-transform: uppercase;
  padding-bottom: 5px;
  position: relative;

  &::after {
    content: '';
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: radial-gradient(
      circle,
      rgba(126, 126, 126, 1) 0%,
      rgba(126, 126, 126, 0) 80%
    );
  }
`;

export const CharacterDescription = styled.p`
  color: rgba(173, 173, 173, 1);
  font-size: 18px;
  text-align: center;
  ${textAlign};
  margin: 5px auto;
`;

export const SkillsArea = styled(Flex)`
  position: absolute;
  right: 0;
  bottom: 10px;
  z-index: 1;
  transition: all 0.3s;
  opacity: 1;
  flex-direction: column;

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
`;

export const SkillImage = styled.img`
  max-width: 60px;
  margin: 12px;
`;

export const SkillsDescription = styled.p`
  text-align: right;
  ${textAlign};
  font-size: 16px;
  margin: 0;
  margin-right: 12px;
  color: #ccc;
`;

// MOBILE

export const MobileCharacterImage = styled(Box)`
  ${minHeight};
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  position: relative;
  margin-bottom: -245px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 300px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.8) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`;

export const MobileItemsWrapper = styled(Flex)`
  overflow: hidden;
  max-width: 100vw;
`;

export const MobileCharacterWrapper = styled(Box)`
  width: 100vw;
  position: relative;
`;

export const PreviousArrow = styled(Box)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
  width: 29px;
  height: 50px;
  cursor: pointer;
  z-index: 2;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/arrow-left.png');
  background-size: 100%;
`;

export const NextArrow = styled(Box)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%) rotate(180deg);
  width: 29px;
  height: 50px;
  cursor: pointer;
  z-index: 2;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/arrow-left.png');
  background-size: 100%;
`;

export const PageIndicatorWrapper = styled(Box)`
  color: rgba(173, 173, 173, 1);
  font-size: 16px;
  text-align: center;
  padding-bottom: 8px;
`;

export const AnimatedFlex = styled(Flex)`
  transition: all 0.3s;
`;

export const MobileDescriptionWrapper = styled(Flex)`
  max-width: 160px;
  flex-direction: column;
  align-items: center;
`;

export const FlexWrapper = styled(Flex)`
  position: relative;
`;

export const MobileSkillsArea = styled(Flex)`
  position: relative;
  transition: all 0.3s;
  opacity: 1;
  flex-direction: column;
`;
